import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { getField, getTranslation, getFieldData, getPagePermissionByPath } from "../../utils/utils";
import { getSVG } from "../../utils/getSvg";
import { GetScreenSize } from "../../utils/screenSize";
import { Drawer, Box, Select, Slide, useTheme, MenuItem } from "@mui/material";
import MainSelect from "../mainSelect/MainSelect";
import Scrollbar from "./Scrollbar";
import NavSection from "./NavSection";
import layoutActions from "../../redux/actions/LayoutActions";
import actions from "../../redux/actions/RestActions";
import modulesActions from "../../redux/actions/ModulesActions";
import branchActions from "../../redux/actions/BranchActions";
import Logo from '../../assets/images/logo.png'

const reformat = (data) => {
  let final = [];
  data?.map((permission) => {
    if (permission.type === "ver") {
      let subPage = [];
      if (permission.subPage?.length > 0) {
        subPage = reformat(permission.subPage);
      }

      final.push({
        isRootMenu: permission.isRootMenu,
        name: permission.name,
        order: permission.order,
        route: permission.route,
        icon: permission.svgKey,
        isActive: permission.isActive,
        key: permission.key,
        subPage,
        isPopup: permission.isPopup
      });
    }
  });

  return final.filter((data) => data.isActive && !data.isPopup).sort((a, b) => a.order - b.order);
};

function SideBar(props) {
  const dictionary = getTranslation('side-nav', props.translation)
  const pagesDictionary = getTranslation('page-names', props.translation)
  const permissions = reformat(props.permissions).sort((a, b) => {
    return a.order - b.order;
  });
  const [finalRestaurants, setFinalRestaurants] = useState([])
  useEffect(() => {
    let final = []
    props.restaurants.map((rest, i) => {
      let obj = { ...rest }
      let target
      let tmp = props.allRestaurantsPermission.find((res) => res.restaurantUuid === rest.uuid)?.permissions
      if (tmp)
        target = getPagePermissionByPath(props.allRestaurantsPermission.find((res) => res.restaurantUuid === rest.uuid)?.permissions, window.location.pathname)
      final.push({ ...obj, isDisabled: !Boolean(target) })
    })
    setFinalRestaurants(final)
  }, [props.selectedRestaurant, props.restaurants, window.location.pathname])
  const screenSize = GetScreenSize();
  const location = useLocation();
  const theme = useTheme();

  const [isHovered, setHovered] = useState(false);

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  const drawerWidth = 190;

  const [isGlobal, setIsGlobal] = useState(false)

  useEffect(() => {
    let page = getPagePermissionByPath(props.permissions, window.location.pathname)
    setIsGlobal(page?.isGlobal)
  }, [window.location.pathname, props.permissions])

  const content = (
    <Box
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          // margin: "0.5rem 0 0 1rem",
          marginTop: 1,
          justifyContent: 'center',
          position: 'relative'
        }}
      >
        {/* {getSVG("logo", theme.palette.primary.main, { width: 150, height: 70 })} */}
        <img src={Logo} style={{ width: 150, height: 90 }} />
        {screenSize.dynamicWidth < 666 ? (
          <Box
            sx={{ position: 'absolute', right: 10 }}
            onClick={() => props.sideNavChange(false)}
          >
            {getSVG("Close", theme.palette.primary.main)}
          </Box>
        ) : null}
      </Box>
      <Scrollbar
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              backgroundColor: '#cccccc',
              borderRadius: '5px',
              width: isHovered ? '5px' : '0', // Show/hide scrollbar on hover
              height: '100%', // Set the height to 100% to cover the entire track
              transition: 'width 0.2s',
            }}
          />
        )}
      >
        <Box sx={{ padding: 2 }}>
          {props.restaurants?.length > 1 ? (
            <Select
              variant="standard"
              disableUnderline
              value={props.selectedRestaurant}
              onChange={(event) => props.setSelectedRest(event.target.value)}
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: '8px',
                height: 35,
                minWidth: '90px',
                fontSize: screenSize.dynamicWidth > 700 ? 16 : 12,
                color: 'primary.main',
                fontWeight: 700,
                padding: '6px',
                margin: '0px 5px 0px 5px',
                outline: 'none',
                //  transform: `${props.sideNavState && screenSize.dynamicWidth > 666 ? `translateX(190px)` : "none"}`,
                transition: "transform 125ms cubic-bezier(0, 0, 0.2, 1) 0ms !important",
                width: '100%',
                fontSize: 14
              }}
              disabled={isGlobal}
            >
              {finalRestaurants.map((restaurant, i) => (
                <MenuItem value={restaurant.uuid} disabled={restaurant.isDisabled} key={`${restaurant.uuid}-restaurant`}>
                  <Box>
                    {getFieldData(restaurant, 'name', props.language)}
                    {restaurant.isDisabled && <Box sx={{ fontStyle: 'italic', fontSize: 11 }}>
                      {getField(dictionary, 'no-access', props.language)}
                    </Box>}
                  </Box>
                </MenuItem>
              ))}
            </Select>

          ) : null}
          <Box>
            <NavSection
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 1,
                },
              }}
              // {...section}
              data={permissions}
              language={props.language}
              pagesDictionary={pagesDictionary}
            />
          </Box>
        </Box>
      </Scrollbar>
    </Box>
  );
  const [dialogState, setDialogState] = useState(props.sideNavState);
  useEffect(() => {
    setDialogState(props.sideNavState);
  }, [props.sideNavState]);
  return (
    <Drawer
      //onMouseLeave={() => props.sideNavChange(false)}
      anchor="left"
      open={dialogState}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          height: screenSize.dynamicWidth > 666 ? "95%" : "100%",
          borderRadius: screenSize.dynamicWidth > 666 ? "10px" : "0px",
          top: screenSize.dynamicWidth > 666 ? "17px" : 0,
          marginLeft: screenSize.dynamicWidth > 666 ? "0.6rem" : 0,
          width: screenSize.dynamicWidth > 666 ? drawerWidth : "100%",
          transform: `${props.sideNavState === true
            ? "none !important "
            : `translateX(${drawerWidth}) !Important`
            }`,
          transition:
            "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !Important",
        },
      }}
      variant="persistent"
    >
      {content}
    </Drawer>
  );
}
const mapStateToProps = (state) => ({
  selectedRestaurant: state.rest.selectedRest,
  restaurants: state.rest.restaurants,
  translation: state.translation.translation,
  language: state.translation.language,
  sideNavState: state.layout.settings?.layoutSettings?.sidebar?.open,
  permissions: state.auth.permissions,
  allRestaurantsPermission: state.rest.allRestaurantsRoles
});

const mapDispatchToProps = (dispatch) => ({
  sideNavChange: (state) => dispatch(layoutActions.sideNavChange(state)),
  setSelectedRest: (value) => dispatch(actions.setSelectedRest(value)),
  setSelectedBranch: (uuid) => dispatch(branchActions.setSelectedBranch(uuid)),
  clearAllBranches: () => dispatch(branchActions.clearAllBranches()),
  getModules: () => dispatch(modulesActions.getModules()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideBar)
);
