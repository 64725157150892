import React, { useEffect, useState, useRef } from "react";

//REDUX
import { connect } from "react-redux";
import deliveryActions from "../../../redux/actions/DeliveryZoneActions";
import branchActions from "../../../redux/actions/BranchActions";
import ordersActions from '../../../redux/actions/OrderActions'

//UTILS
import { getFieldData, getTranslation, getField, reformatArray, getPagePermission } from "../../../utils/utils";

//COMPONENTS
import {
  Dialog,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Formik, Form } from "formik";
import IOSwitch from "../../../components/switch/switch";
import InputField from "../../../components/inputField/InputField";
import LoadingButton from "../../../components/loadingButton/LoadingButton";
import Loading from '../../../components/loaders/Loading'
import * as yup from 'yup'
import CancelModal from "../../../components/ProductAddEdit/CancelModal";

const DeliverySettings = ({
  deliverySettingsModalState,
  setDeliverySettingsModalState,
  setSelectedBranch,
  selectedBranch,
  branches,
  language,
  translation,
  getOnlineDeliverySettings,
  addEditDeliverySettings,
  loading,
  actionLoading,
  settings,
  selectedRestaurant,
  restaurants,
  updateDispatchSettings,
  config,
  permissions
}) => {
  const page = getPagePermission(permissions, 'onlineDeliverySettings')
  const [availableSettings, setAvailableSettings] = useState([])
  useEffect(() => {
    let keys = page?.subPage.map((page) => page.key)
    setAvailableSettings(keys)
  }, [page])
  //DICTIONARY
  const dictionary = getTranslation("delivery-settings", translation);

  const [currencyId, setCurrencyId] = useState('')

  useEffect(() => {
    restaurants.map((rest) => {
      if (rest.uuid === selectedRestaurant)
        setCurrencyId(rest.currency.code)
    })
  }, [restaurants, selectedRestaurant])

  const formRef = useRef()

  const [cancelModalState, setCancelModalState] = useState(false)

  const closeModal = () => {
    setDeliverySettingsModalState(false);
  };

  const validation = yup.object().shape({
    acceptPreorders: yup.boolean(),
    acceptPreordersOnly: yup.boolean(),
    minAfter: yup.number().when(['acceptPreorders', 'acceptPreordersOnly'], {
      is: (acceptPreorders, acceptPreordersOnly) => acceptPreorders && acceptPreordersOnly,
      then: yup.number().required(getField(dictionary, 'min-req', language))
    }),
    serviceCharge: yup.boolean(),
    serviceChargeRate: yup.number().when('serviceCharge', {
      is: true,
      then: yup.number().required(getField(dictionary, 'service-req', language))
    }),
    isMinOrderAmount: yup.boolean(),
    minOrderAmount: yup.number().when('isMinOrderAmount', {
      is: true,
      then: yup.number().required(getField(dictionary, 'min-amt-req', language))
    }),
    defaultPreparationTime: yup.number().required(getField(dictionary, 'prep-time-req', language))
  })

  const initialValues = {
    isDispatch: config ? config.values.enabled ? true : false : false,
    acceptPreorders: settings ? settings?.preOrdersEnabled : true,
    acceptPreordersOnly: settings?.preOrdersSettings ? true : false,
    minAfter: settings?.preOrdersSettings?.value || "",
    timeInterval: settings?.preOrdersSettings?.timeUnit || "days",
    serviceCharge: settings?.serviceCharge ? true : false,
    serviceChargeRate: settings?.serviceCharge || "",
    defaultPreparationTime: settings?.preparationTime || "",
    isMinOrderAmount: settings?.minOrderAmount ? true : false,
    minOrderAmount: settings?.minOrderAmount || '',
    minAmountAppliedOnPickup: settings?.minAmountAppliedOnPickup ? true : false,
    isAutoAcceptEnabled: settings ? settings.isAutoAcceptEnabled : false
  };

  const handleChangeBranch = (branch) => {
    setSelectedBranch(branch);
  };

  const timeIntervals = [
    "minutes",
    "hours",
    "days",
    "weeks",
    "months",
    "years",
  ];

  const handleSubmit = (finalValues) => {
    updateDispatchSettings(selectedRestaurant, {
      values: {
        enabled: finalValues.isDispatch
      }
    }, closeModal)
    let final = { ...finalValues }
    let final2 = {
      preOrdersEnabled: final.acceptPreorders,
      preOrdersSettings: final.acceptPreordersOnly && final.acceptPreorders ? {
        timeUnit: final.timeInterval,
        value: final.minAfter
      } : null,
      preparationTime: final.defaultPreparationTime,
      serviceCharge: final.serviceCharge ? final.serviceChargeRate : null,
      minAmountAppliedOnPickup: final.minAmountAppliedOnPickup,
      minOrderAmount: final.isMinOrderAmount ? final.minOrderAmount : null,
      isAutoAcceptEnabled: final.isAutoAcceptEnabled
    }
    addEditDeliverySettings(selectedRestaurant, selectedBranch, final2, closeModal)
  }

  useEffect(() => {
    if (selectedBranch !== '0' && selectedBranch !== 0)
      getOnlineDeliverySettings(selectedRestaurant, selectedBranch)
  }, [selectedBranch])

  return (
    <Dialog open={deliverySettingsModalState} onClose={() => {
      if (!formRef.current?.dirty)
        closeModal()
      else setCancelModalState(true)
    }}>
      <Box padding={2}>
        {loading && <Loading />}
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validation} innerRef={formRef}>
          {(formik) => (
            <Form>
              <Grid container spacing={1}>
                {availableSettings.includes('dispatch') && <Grid item xs={12}>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <IOSwitch
                        checked={formik.values.isDispatch}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "isDispatch",
                            e.target.checked
                          );
                        }}
                      />
                    }
                    label={`${getField(
                      dictionary,
                      "dispatch",
                      language
                    )}`}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 700,
                        color: "pageTitle",
                      },
                    }}
                  />
                </Grid>}
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Select
                    variant="outlined"
                    sx={{
                      //color: '#7D4090',
                      backgroundColor: "#ffffff",
                      borderRadius: "8px",
                      height: 36,
                      width: "200px",
                    }}
                    value={selectedBranch}
                    inputProps={{
                      name: "branch",
                      id: "branch-select",
                    }}
                    onChange={(event) => handleChangeBranch(event.target.value)}
                  >
                    {branches?.filter((branch) => branch.isActive).map((branch, i) => (
                      <MenuItem key={i} value={branch.uuid}>
                        {getFieldData(branch, "name", language)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {availableSettings.includes('acceptPreorders') && <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <FormControlLabel
                    sx={{
                      marginLeft: -0.5,
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 700,
                        color: "pageTitle",
                      },
                    }}
                    labelPlacement="start"
                    label={getField(dictionary, "accept-preorders", language)}
                    control={
                      <IOSwitch
                        checked={formik.values.acceptPreorders}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "acceptPreorders",
                            e.target.checked
                          )
                        }
                      />
                    }
                  />
                </Grid>}
                {availableSettings.includes('acceptPreordersOnly') && <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <FormControlLabel
                        sx={{
                          marginLeft: -0.5,
                          "& .MuiFormControlLabel-label": {
                            fontWeight: 700,
                            color: "pageTitle",
                          },
                        }}
                        labelPlacement="start"
                        label={getField(
                          dictionary,
                          "accept-preorders-only",
                          language
                        )}
                        control={
                          <IOSwitch
                            checked={formik.values.acceptPreordersOnly}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "acceptPreordersOnly",
                                e.target.checked
                              )
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={2}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {formik.values.acceptPreordersOnly && <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: 700,
                          color: "pageTitle",
                        }}
                      >
                        {getField(dictionary, "min-after", language)}
                      </Box>}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={3}
                      sx={{ display: "flex", alignItems: "flex-start" }}
                    >
                      {formik.values.acceptPreordersOnly &&
                        <InputField
                          fullWidth
                          size="small"
                          type='number'
                          value={formik.values.minAfter}
                          onChange={(e) =>
                            formik.setFieldValue("minAfter", e.target.value)
                          }
                          placeholder={getField(
                            dictionary,
                            "min-after-placeholder",
                            language
                          )}
                          error={
                            formik.errors.minAfter &&
                            Boolean(formik.touched.minAfter)
                          }
                          helperText={
                            formik.touched.minAfter && formik.errors.minAfter
                          }
                        />}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={3}
                      sx={{ display: "flex", alignItems: "flex-start" }}
                    >
                      {formik.values.acceptPreordersOnly &&
                        <Select
                          variant="outlined"
                          sx={{
                            //color: '#7D4090',
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            width: "100%",
                          }}
                          size="small"
                          value={formik.values.timeInterval}
                          onChange={(event) =>
                            formik.setFieldValue('timeInterval', event.target.value)
                          }
                        >
                          {timeIntervals?.map((interval, i) => (
                            <MenuItem key={i} value={interval}>
                              {getField(dictionary, interval, language)}
                            </MenuItem>
                          ))}
                        </Select>}
                    </Grid>
                  </Grid>
                </Grid>}
                {availableSettings.includes('serviceCharge') && <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <FormControlLabel
                        sx={{
                          marginLeft: -0.5,
                          "& .MuiFormControlLabel-label": {
                            fontWeight: 700,
                            color: "pageTitle",
                          },
                        }}
                        labelPlacement="start"
                        label={getField(dictionary, "service-charge", language)}
                        control={
                          <IOSwitch
                            checked={formik.values.serviceCharge}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "serviceCharge",
                                e.target.checked
                              )
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {formik.values.serviceCharge && <InputField
                        fullWidth
                        size="small"
                        type='number'
                        value={formik.values.serviceChargeRate}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "serviceChargeRate",
                            e.target.value
                          )
                        }
                        placeholder={getField(
                          dictionary,
                          "service-charge-rate-placeholder",
                          language
                        )}
                        error={
                          formik.errors.serviceChargeRate &&
                          Boolean(formik.touched.serviceChargeRate)
                        }
                        helperText={
                          formik.touched.serviceChargeRate &&
                          formik.errors.serviceChargeRate
                        }
                      />}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {formik.values.serviceCharge &&
                        <Box
                          sx={{
                            display: "flex",
                            textAlign: "center",
                            fontWeight: 700,
                            color: "pageTitle",
                          }}
                        >
                          %
                        </Box>}
                    </Grid>
                  </Grid>
                </Grid>}
                {availableSettings.includes('minimumOrderAmount') && <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <FormControlLabel
                        sx={{
                          marginLeft: -0.5,
                          "& .MuiFormControlLabel-label": {
                            fontWeight: 700,
                            color: "pageTitle",
                          },
                        }}
                        labelPlacement="start"
                        label={getField(dictionary, "min-amount", language)}
                        control={
                          <IOSwitch
                            checked={formik.values.isMinOrderAmount}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "isMinOrderAmount",
                                e.target.checked
                              )
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {formik.values.isMinOrderAmount &&
                        <InputField
                          fullWidth
                          size="small"
                          type='number'
                          value={formik.values.minOrderAmount}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "minOrderAmount",
                              e.target.value
                            )
                          }
                          placeholder={getField(
                            dictionary,
                            "min-order-amount-placeholder",
                            language
                          )}
                          error={
                            formik.errors.minOrderAmount &&
                            Boolean(formik.touched.minOrderAmount)
                          }
                          helperText={
                            formik.touched.serviceChargeRate &&
                            formik.errors.serviceChargeRate
                          }
                        />}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {formik.values.isMinOrderAmount &&
                        <Box
                          sx={{
                            display: "flex",
                            textAlign: "center",
                            fontWeight: 700,
                            color: "pageTitle",
                          }}
                        >
                          {currencyId}
                        </Box>}
                    </Grid>
                  </Grid>
                </Grid>}
                {availableSettings.includes('applyMinimumAmountPickup') && <Grid item xs={12}>
                  <FormControlLabel
                    sx={{
                      marginLeft: -0.5,
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 700,
                        color: "pageTitle",
                      },
                    }}
                    labelPlacement="start"
                    label={getField(dictionary, "min-amount-applied-pickup", language)}
                    control={
                      <IOSwitch
                        checked={formik.values.minAmountAppliedOnPickup}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "minAmountAppliedOnPickup",
                            e.target.checked
                          )
                        }
                      />
                    }
                  />
                </Grid>}
                {availableSettings.includes('defaultPreparationTime') && <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: 700,
                          color: "pageTitle",
                        }}
                      >
                        {getField(dictionary, "default-prep-time", language)}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <InputField
                        fullWidth
                        size="small"
                        type='number'
                        value={formik.values.defaultPreparationTime}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "defaultPreparationTime",
                            e.target.value
                          )
                        }
                        placeholder={getField(
                          dictionary,
                          "default-prep-time-placeholder",
                          language
                        )}
                        error={
                          formik.errors.defaultPreparationTime &&
                          Boolean(formik.touched.defaultPreparationTime)
                        }
                        helperText={
                          formik.touched.defaultPreparationTime &&
                          formik.errors.defaultPreparationTime
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          fontWeight: 700,
                          color: "pageTitle",
                        }}
                      >
                        {getField(dictionary, "mins", language)}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>}
                {availableSettings.includes('autoAcceptOrders') && <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <FormControlLabel
                    sx={{
                      marginLeft: -0.5,
                      "& .MuiFormControlLabel-label": {
                        fontWeight: 700,
                        color: "pageTitle",
                      },
                    }}
                    labelPlacement="start"
                    label={getField(dictionary, "auto-accept", language)}
                    control={
                      <IOSwitch
                        checked={formik.values.isAutoAcceptEnabled}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "isAutoAcceptEnabled",
                            e.target.checked
                          )
                        }
                      />
                    }
                  />
                </Grid>}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 1,
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ margin: "0px 5px 0px 5px" }}
                  onClick={() => {
                    if (!formik.dirty)
                      closeModal()
                    else setCancelModalState(true)
                  }}
                >
                  {getField(dictionary, "cancel", language)}
                </Button>
                <LoadingButton
                  variant="contained"
                  sx={{ margin: "0px 5px 0px 5px" }}
                  onClick={formik.handleSubmit}
                  title={getField(dictionary, "save", language)}
                  isSubmitting={actionLoading}
                />
              </Box>
              <CancelModal
                isOpen={cancelModalState}
                onCancel={() => setCancelModalState(false)}
                action={() => {
                  closeModal()
                  setCancelModalState(false)
                }}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};
const mapStateToProps = (state) => ({
  deliverySettingsModalState: state.zone.deliverySettingsModalState,
  branches: state.branch.branches,
  selectedBranch: state.branch.selectedBranchOnlineDelivery,
  translation: state.translation.translation,
  language: state.translation.language,
  settings: state.zone.deliverySettings,
  actionLoading: state.zone.deliverySettingsActionLoading,
  loading: state.zone.deliverySettingsLoading || state.order.dispatchSettingsLoading,
  selectedRestaurant: state.rest.selectedRest,
  restaurants: state.rest.restaurants,
  config: state.order.config,
  permissions: state.auth.permissions
});
const mapDispatchToProps = (dispatch) => ({
  setDeliverySettingsModalState: (data) =>
    dispatch(deliveryActions.setDeliverySettingsModalState(data)),
  setSelectedBranch: (data) => dispatch(branchActions.setSelectedBranchOnlineDelivery(data)),
  getOnlineDeliverySettings: (ruuid, bruuid) => dispatch(deliveryActions.getOnlineDeliverySettings(ruuid, bruuid)),
  addEditDeliverySettings: (ruuid, bruuid, data, close) => dispatch(deliveryActions.addEditDeliverySettings(ruuid, bruuid, data, close)),
  updateDispatchSettings: (ruuid, data, onClose) => dispatch(ordersActions.updateDispatchSettings(ruuid, data, onClose))
});
export default connect(mapStateToProps, mapDispatchToProps)(DeliverySettings);
